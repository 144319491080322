import React from "react"
// import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { Aside } from "components/blog/aside"
import { Breadcrumb } from "components/breadcrumb"
import { DefaultHeader } from "components/headers/default-header"

const ReplyBasedOrUrlBased = ({ location }) => {
  const title = "インスタントウィンはリプライ型とURL型どっちに対応？"
  const list = [
    { title: "よくある質問", link: "/qa" },
    { title: title, link: location.pathname },
  ]

  return (
    <>
      <DefaultHeader />
      <Layout>
        <Seo
          pagetitle={`FAQ | ${title}`}
          pagedesc="リプライ型とURL型どちらにも対応しています。抽選結果をダイレクトメッセージで通知する方式を推奨しています。メッセージにURLが含まれており、そのURLに遷移することで結果を確認できます。"
          pagepath={location.pathname}
        />
        <Breadcrumb title="よくある質問" items={list} />
        <div className="qa-page l-content l-container">
          <main className="l-main-content">
            <h1 className="qa-page__title">
              PARKLoTで開催されるキャンペーンはリプライ型とURL型どちらですか？
            </h1>
            <p className="qa-page__answer">URL型です。</p>
            <p className="qa-page__text">
              抽選結果はダイレクトメッセージで通知されます。メッセージにURLが含まれており、そのURLをブラウザで表示することで結果を確認できます。
            </p>

            <div className="qa-page__detail-container">
              <div>
                <h2 className="qa-page__h2">URL型とリプライ型の違いについて</h2>

                <p className="qa-page__text">
                  抽選結果の通知方式がいくつかあります。それぞれの特徴を説明します。
                </p>

                <h3 className="qa-page__h3">URL型とは？</h3>
                <p className="qa-page__text">
                  キャンペーンの抽選結果がURLで通知される方式の参加方法です。
                  <br />
                  参加者はURLをクリックして、遷移先のウェブサイトで抽選結果を確認します。
                </p>

                <h3 className="qa-page__h3">リプライ型とは？</h3>
                <p className="qa-page__text">
                  キャンペーンの抽選結果がリプライで通知される方式です。
                  <br />
                  キャンペーンに参加してないユーザーもキャンペーン抽選結果を閲覧できます。
                </p>
              </div>

              <div>
                <h2 className="qa-page__h2">
                  URL型(URLをDMで送信)をPARKLoTで採用している理由
                </h2>

                <p className="qa-page__text">
                  「ログイン不要で景品を受け取れるようにする」ためにこの方式を採用しております。
                  <br />
                  ※同様の方式でもログインが必須となっているサービスもございますので、ご注意ください。
                  <br />
                  ※ダイレクトメッセージの送信上限に達した場合はメッセージでの通知は届かないため、その場合はログインして結果を確認していただく必要があります。
                </p>

                <p className="qa-page__text">
                  リプライ型の場合、「抽選結果の確認にログインが必須」となります。
                  <br />
                  Twitterの仕様上、リプライは参加者全員が閲覧できてしまうため、アカウントの確認のために応募したTwitterアカウントでのログインが必須となります。
                </p>

                <p className="qa-page__text">
                  結果通知方式をリプライ型へ変更するカスタマイズも可能ですが、プレゼントの受け取りURLが当選者以外のユーザーにも知られてしまいます。
                  そのため、結果確認・プレゼント受け取り時の両方でアカウント確認のためにTwitterログインが必須となります。
                </p>

                <p className="qa-page__text">
                  アカウントによってはリプライの場合はユーザーに通知がいかない事象が確認されているため、基本的には非推奨とさせていただいています。
                </p>
              </div>
            </div>
          </main>
          <Aside />
        </div>
      </Layout>
    </>
  )
}

export default ReplyBasedOrUrlBased
